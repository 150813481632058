define("discourse/plugins/discourse-circles/discourse/circles-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("circles", function () {
      this.route("new");
    });
    this.route("circle", {
      path: "/circles/*slug_path_with_id",
      resetNamespace: true
    }, function () {
      this.route("about");
      this.route("activity", function () {
        this.route("posts");
        this.route("topics");
      });
      this.route("messages", function () {
        this.route("inbox");
        this.route("archive");
      });
      this.route("members");
      this.route("staff");
      this.route("requests");
      this.route("manage", function () {
        this.route("profile");
        this.route("advanced");
      });
    });
  }
});