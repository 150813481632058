define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/dropdown", ["exports", "@ember/helper", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/combobox", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _dropdownSelectBox, _combobox, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldDropdown extends _combobox.default {
    get none() {
      return this.args.none || {
        name: this.noneLabel
      };
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SearchFieldWireFrame
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @value={{@value}}
        >
          <DropdownSelectBox
            name={{@filter.name}}
            @nameProperty={{@nameProperty}}
            @valueProperty={{@valueProperty}}
            @value={{@value}}
            @content={{this.content}}
            @options={{hash none=this.none showCaret=true}}
          />
        </SearchFieldWireFrame>
      
    */
    {
      "id": "+ici/AX6",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@controller\",\"@filter\",\"@value\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,3,[30,3,[\"name\"]]]],[[\"@nameProperty\",\"@valueProperty\",\"@value\",\"@content\",\"@options\"],[[30,5],[30,6],[30,4],[30,0,[\"content\"]],[28,[32,2],null,[[\"none\",\"showCaret\"],[[30,0,[\"none\"]],true]]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@value\",\"@nameProperty\",\"@valueProperty\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/dropdown.js",
      "scope": () => [_wireFrame.default, _dropdownSelectBox.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldDropdown;
});