define("discourse/plugins/discourse-circles/discourse/routes/circle-activity-topics", ["exports", "discourse/routes/group-activity-topics", "I18n"], function (_exports, _groupActivityTopics, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleActivityTopicsRoute extends _groupActivityTopics.default {
    titleToken() {
      const filterText = _I18n.default.t("filters.latest.title");
      return _I18n.default.t("filters.with_category", {
        category: this.modelFor("circle").name,
        filter: filterText
      });
    }
    async model() {
      const circleModel = this.modelFor("circle");
      if (circleModel.can_read_topics) {
        const topicList = await this.store.findFiltered("topicList", {
          filter: circleModel.slug ? `c/${circleModel.slug}/${circleModel.category_id}/l/latest` : `c/${circleModel.category_id}/l/latest`,
          params: {
            ascending: false,
            circles_no_definitions: true
          }
        });

        // in case the topics are doing something fancy because they belong to a
        // circle
        const basicCircle = topicList?.topics?.[0];
        topicList?.topics?.forEach(topic => topic.circle = basicCircle);
        return topicList;
      }
      return null;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.circleModel = this.modelFor("circle");
    }
  }
  _exports.default = CircleActivityTopicsRoute;
});