define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/base", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldBase extends _component.default {
    get placeholder() {
      return this.args.placeholder || this.args.filter.name;
    }
  }
  _exports.default = SearchFieldBase;
});