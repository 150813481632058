define("discourse/plugins/discourse-circles/discourse/templates/circle/activity", ["exports", "@glimmer/component", "ember-route-template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _emberRouteTemplate, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CircleActivity extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <section class="circles-tab-content">
            <div class="circles-activity-wrapper">
              <section class="circles-activity-content">
                {{outlet}}
              </section>
            </div>
          </section>
        
    */
    {
      "id": "eafPCvTW",
      "block": "[[[1,\"\\n      \"],[10,\"section\"],[14,0,\"circles-tab-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"circles-activity-wrapper\"],[12],[1,\"\\n          \"],[10,\"section\"],[14,0,\"circles-activity-content\"],[12],[1,\"\\n            \"],[46,[28,[31,1],null,null],null,null,null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[],false,[\"component\",\"-outlet\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circle/activity.js",
      "isStrictMode": true
    }), this))();
  });
});