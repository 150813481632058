define("discourse/plugins/discourse-circles/discourse/routes/circle-activity-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleActivityIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel(transition) {
      if (transition.urlMethod === "replace") {
        this.router.replaceWith("circle.activity.topics");
      } else {
        this.router.transitionTo("circle.activity.topics");
      }
    }
  }
  _exports.default = CircleActivityIndexRoute;
});