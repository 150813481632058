define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/confirm", ["exports", "@ember/template", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/checkbox", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/base", "@ember/component", "@ember/template-factory"], function (_exports, _template, _checkbox, _base, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomFieldConfirm extends _base.default {
    get description() {
      const description = super.description;
      if (!description) {
        return null;
      }
      return (0, _template.htmlSafe)(description);
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldCheckbox
          ...attributes
          @name={{@field.name}}
          @title={{this.title}}
          @showTitle={{this.shouldDisplayTitle}}
          @description={{this.description}}
          @showDescription={{this.shouldDisplayDescription}}
          @placeholder={{this.placeholder}}
          @showPlaceholder={{this.shouldDisplayPlaceholder}}
          @required={{@field.required}}
          @autofocus={{@autofocus}}
          @value={{@value}}
          @onChange={{@onChange}}
          @validation={{@validation}}
        >
          {{yield}}
        </FormFieldCheckbox>
      
    */
    {
      "id": "KCxLPE36",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@name\",\"@title\",\"@showTitle\",\"@description\",\"@showDescription\",\"@placeholder\",\"@showPlaceholder\",\"@required\",\"@autofocus\",\"@value\",\"@onChange\",\"@validation\"],[[30,2,[\"name\"]],[30,0,[\"title\"]],[30,0,[\"shouldDisplayTitle\"]],[30,0,[\"description\"]],[30,0,[\"shouldDisplayDescription\"]],[30,0,[\"placeholder\"]],[30,0,[\"shouldDisplayPlaceholder\"]],[30,2,[\"required\"]],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@field\",\"@autofocus\",\"@value\",\"@onChange\",\"@validation\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/confirm.js",
      "scope": () => [_checkbox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomFieldConfirm;
});