define("discourse/plugins/discourse-circles/discourse/components/circles/privacy-status", ["exports", "@glimmer/component", "@ember/object", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/discourse-circles/discourse/models/circle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dIcon, _I18n, _circle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesPrivacyStatus extends _component.default {
    s;
    get text() {
      const [key] = this.privacyLevelData;
      const textType = this.args.extendedName ? "name" : "extended_name";
      return _I18n.default.t(`discourse_circles.form.privacy_levels.${key}.${textType}`);
    }
    static #_ = (() => dt7948.n(this.prototype, "text", [(0, _object.computed)("args.model.privacy_level", "args.extendedText")]))();
    get icon() {
      const props = this.privacyLevelData[1];
      return props?.icon;
    }
    get privacyLevelData() {
      return Object.entries(_circle.default.PRIVACY_LEVELS).find(level => {
        const props = level[1];
        return props.value === this.args.model.privacy_level;
      });
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="circles-privacy-status" ...attributes>
          {{#if this.icon}}
            {{dIcon this.icon}}
          {{/if}}
          {{this.text}}
        </div>
      
    */
    {
      "id": "aKLYn0aU",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"circles-privacy-status\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"        \"],[1,[28,[32,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[30,0,[\"text\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/privacy-status.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesPrivacyStatus;
});