define("discourse/plugins/discourse-circles/discourse/components/circles/background", ["exports", "@ember/helper", "discourse/components/light-dark-img", "discourse/components/plugin-outlet", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _lightDarkImg, _pluginOutlet, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class="circles__background
        {{if @model.hasBackground '--has-image'}}
        {{unless @model.hasBackground '--blank'}}"
      ...attributes
    >
      <PluginOutlet @name="circles-background" @outletArgs={{hash model=@model}}>
        {{#if @model.hasBackground}}
          <LightDarkImg
            @lightImg={{@model.category.uploaded_background}}
            @darkImg={{@model.category.uploaded_background_dark}}
          />
        {{/if}}
      </PluginOutlet>
    </div>
  
  */
  {
    "id": "gxVEQx1k",
    "block": "[[[1,\"\\n  \"],[11,0],[16,0,[29,[\"circles__background\\n      \",[52,[30,1,[\"hasBackground\"]],\"--has-image\"],\"\\n      \",[52,[51,[30,1,[\"hasBackground\"]]],\"--blank\"]]]],[17,2],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-background\",[28,[32,1],null,[[\"model\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"hasBackground\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@lightImg\",\"@darkImg\"],[[30,1,[\"category\",\"uploaded_background\"]],[30,1,[\"category\",\"uploaded_background_dark\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@model\",\"&attrs\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/background.js",
    "scope": () => [_pluginOutlet.default, _helper.hash, _lightDarkImg.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "background"));
});