define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/combobox", ["exports", "@ember/helper", "select-kit/components/combo-box", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _comboBox, _base, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldDropdown extends _base.default {
    get noneLabel() {
      return this.placeholder;
    }
    get content() {
      return this.args.filter.options;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SearchFieldWireFrame
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @value={{@value}}
        >
          <Combobox
            name={{@filter.name}}
            @nameProperty={{@nameProperty}}
            @valueProperty={{@valueProperty}}
            @value={{@value}}
            @none="test"
            @content={{this.content}}
            @options={{hash translatedNone=this.noneLabel}}
          />
        </SearchFieldWireFrame>
      
    */
    {
      "id": "gO5KQz7+",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@controller\",\"@filter\",\"@value\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,3,[30,3,[\"name\"]]]],[[\"@nameProperty\",\"@valueProperty\",\"@value\",\"@none\",\"@content\",\"@options\"],[[30,5],[30,6],[30,4],\"test\",[30,0,[\"content\"]],[28,[32,2],null,[[\"translatedNone\"],[[30,0,[\"noneLabel\"]]]]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@value\",\"@nameProperty\",\"@valueProperty\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/combobox.js",
      "scope": () => [_wireFrame.default, _comboBox.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldDropdown;
});