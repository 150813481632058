define("discourse/plugins/discourse-circles/discourse/lib/model-attribute", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModelAttribute {
    #name;
    #displayName;
    #defaultValue;
    #options;
    #validation;
    constructor(name) {
      let {
        displayName,
        defaultValue,
        validation,
        ...options
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.#name = name;
      this.#displayName = displayName;
      this.#defaultValue = defaultValue;
      this.#options = Object.freeze(options || {});
      this.#validation = validation || {};
    }

    /**
     * Get the name of the attribute.
     * @return {string} The name of the attribute.
     */
    get name() {
      return this.#name;
    }

    /**
     * Get the display name of the attribute.
     * @return {string} The display name of the attribute.
     */
    get displayName() {
      return this.#displayName;
    }

    /**
     * Get the default value of the attribute.
     * @return {*} The default value of the attribute.
     */
    get defaultValue() {
      return this.#defaultValue;
    }

    /**
     * Get the options of the attribute.
     * @return {Object} The options of the attribute.
     */
    get options() {
      return this.#options;
    }

    /**
     * Get the minimum length of the attribute.
     * @return {number} The minimum length of the attribute.
     */
    get minLength() {
      return this.#validation.minLength;
    }

    /**
     * Get the maximum length of the attribute.
     * @return {number} The maximum length of the attribute.
     */
    get maxLength() {
      return this.#validation.maxLength;
    }

    /**
     * Check if the attribute is required.
     * @return {boolean} True if the attribute is required, false otherwise.
     */
    get isRequired() {
      return this.#validation.presence;
    }

    /**
     * Check if the attribute has validations.
     * @returns {boolean} True if the attribute has validations, false otherwise.
     */
    get hasValidations() {
      return this.#validation.presence || this.#validation.minLength || this.#validation.maxLength || this.#validation.custom;
    }
    #embeddedValidations(value) {
      const errors = [];
      const {
        presence,
        minLength,
        maxLength
      } = this.#validation;
      if (presence && ((0, _utils.isEmpty)(value) || typeof value === "boolean" && !value)) {
        errors.push("is required");
        // let's short circuit here to avoid displaying multiple errors if the value is missing
        return {
          errors,
          shortCircuit: true
        };
      }
      if (minLength && value.length < minLength) {
        errors.push(`must be at least ${minLength} characters`);
      }
      if (maxLength && value.length > maxLength) {
        errors.push(`must be at most ${maxLength} characters`);
      }
      return {
        errors
      };
    }
    #handleCustomValidationResult(customValidation, previousErrors) {
      switch (typeof customValidation) {
        case "object":
          if (customValidation.success) {
            return {
              success: customValidation.success
            };
          }
          if (customValidation.errors) {
            previousErrors.push(...customValidation.errors);
          }
          break;
        case "string":
          previousErrors.push(customValidation);
          break;
        default:
          throw new Error(`${this.name}: custom validation must return an object or a string`);
      }
      return {
        errors: previousErrors
      };
    }

    /**
     * Validate the attribute.
     * @param {*} value - The value to validate.
     * @param {Object} record - The record containing the attribute.
     * @param {Object} options - The options for validation.
     * @return {{success: (true|string)}|{errors: (string|string[])}} The result of the validation.
     */
    validate(value, record, options) {
      const {
        errors,
        shortCircuit
      } = this.#embeddedValidations(value, record, options);
      let result = {
        errors
      };
      if (shortCircuit) {
        return this.#validation.async ? Promise.resolve(result) : result;
      }
      if (this.#validation?.custom) {
        if (typeof this.#validation?.custom === "function") {
          if (this.#validation.async) {
            return Promise.resolve(this.#validation.custom(value, record, options)).then(customValidationResult => {
              return this.#handleCustomValidationResult(customValidationResult, result.errors);
            });
          }
          return this.#handleCustomValidationResult(this.#validation.custom(value, record, options), result.errors);
        } else {
          throw new Error(`${this.name}: custom validation must be a function`);
        }
      }
      if (result.errors.length === 0) {
        result = {
          success: true
        };
      }
      return this.#validation.async ? Promise.resolve(result) : result;
    }
  }
  _exports.default = ModelAttribute;
});