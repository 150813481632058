define("discourse/plugins/discourse-circles/discourse/components/circles/card/actions", ["exports", "@ember/helper", "discourse/components/plugin-outlet", "discourse/helpers/route-action", "discourse/plugins/discourse-circles/discourse/components/circles/membership-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _pluginOutlet, _routeAction, _membershipButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CircleCardAction = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="circles-card__actions">
      <PluginOutlet
        @name="circles-card-actions"
        @outletArgs={{hash
          model=@model
          showMembershipButton=@showMembershipButton
        }}
      >
        {{#if @showMembershipButton}}
          <CirclesMembershipButton
            @model={{@model}}
            @showLogin={{routeAction "showLogin"}}
          />
        {{/if}}
      </PluginOutlet>
    </div>
  
  */
  {
    "id": "Is37paph",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"circles-card__actions\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-card-actions\",[28,[32,1],null,[[\"model\",\"showMembershipButton\"],[[30,1],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[8,[32,2],null,[[\"@model\",\"@showLogin\"],[[30,1],[28,[32,3],[\"showLogin\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@model\",\"@showMembershipButton\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/card/actions.js",
    "scope": () => [_pluginOutlet.default, _helper.hash, _membershipButton.default, _routeAction.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "actions:CircleCardAction"));
  var _default = _exports.default = CircleCardAction;
});