define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base", ["exports", "@glimmer/component", "@ember/string", "I18n"], function (_exports, _component, _string, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldBase extends _component.default {
    displayTitleByDefault = true;
    displayDescriptionByDefault = false;
    displayPlaceholderByDefault = true;
    #shouldDisplayProperty(argumentName, defaultSetting) {
      const hideSetting = this.args[`hide${(0, _string.capitalize)(argumentName)}`];
      const showSetting = this.args[`show${(0, _string.capitalize)(argumentName)}`];
      if (hideSetting && showSetting) {
        throw new Error(`You cannot set both hide${(0, _string.capitalize)(argumentName)} and show${(0, _string.capitalize)(argumentName)}`);
      }
      if (showSetting !== undefined) {
        return !!showSetting;
      }
      return hideSetting !== undefined ? !hideSetting : defaultSetting;
    }
    get fieldName() {
      return `circles-field__${(0, _string.dasherize)(this.args.name)}`;
    }
    get shouldDisplayTitle() {
      return this.#shouldDisplayProperty("title", this.displayTitleByDefault);
    }
    get title() {
      if (!this.shouldDisplayTitle) {
        return null;
      }
      // allow for empty strings or nulls to be passed in
      if (this.args.title === undefined) {
        return _I18n.default.t(this.titleI18n);
      }
      return this.args.title;
    }
    get titleI18n() {
      return this.args.titleI18n || `discourse_circles.form.${this.args.name}.title`;
    }
    get shouldDisplayDescription() {
      return this.#shouldDisplayProperty("description", this.displayDescriptionByDefault);
    }
    get description() {
      if (!this.shouldDisplayDescription) {
        return null;
      }
      // allow for empty strings or nulls to be passed in
      if (this.args.description === undefined) {
        return _I18n.default.t(this.descriptionI18n);
      }
      return this.args.description;
    }
    get descriptionI18n() {
      return this.args.descriptionI18n || `discourse_circles.form.${this.args.name}.description`;
    }
    get shouldDisplayPlaceholder() {
      return this.#shouldDisplayProperty("placeholder", this.displayPlaceholderByDefault);
    }
    get placeholder() {
      if (!this.shouldDisplayPlaceholder) {
        return null;
      }
      // allow for empty strings or nulls to be passed in
      if (this.args.placeholder === undefined) {
        return _I18n.default.t(this.placeholderI18n);
      }
      return this.args.placeholder || _I18n.default.t(this.placeholderI18n);
    }
    get placeholderI18n() {
      return this.args.placeholderI18n || `discourse_circles.form.${this.args.name}.placeholder`;
    }
  }
  _exports.default = FormFieldBase;
});