define("discourse/plugins/discourse-circles/discourse/components/circles/notifications-button", ["exports", "I18n", "select-kit/components/category-notifications-button"], function (_exports, _I18n, _categoryNotificationsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _categoryNotificationsButton.default.extend({
    pluginApiIdentifiers: ["circles-notifications-button"],
    classNames: ["circles-notifications-button"],
    selectKitOptions: {
      i18nPrefix: "discourse_circles.circle.notifications",
      headerAriaLabel: _I18n.default.t("discourse_circles.circle.notifications.title")
    }
  });
});