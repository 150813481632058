define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/validation-tip", ["exports", "@glimmer/component", "discourse/components/input-tip", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/conditional-char-counter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _inputTip, _conditionalCharCounter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ValidationTip extends _component.default {
    get shouldShowValidationTip() {
      return this.args.validation || this.args.maxLength;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShowValidationTip}}
          <div class="validation-tip">
            {{#if @validation.loading}}
              <div class="tip" ...attributes>
                <span class="spinner"></span>
                <span class="tip-content">{{@validation.reason}}</span>
              </div>
            {{else}}
              <InputTip @validation={{@validation}} />
            {{/if}}
            <ConditionalCharCounter @value={{@value}} @max={{@maxLength}} />
          </div>
        {{/if}}
      
    */
    {
      "id": "a5hujaB7",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShowValidationTip\"]],[[[1,\"      \"],[10,0],[14,0,\"validation-tip\"],[12],[1,\"\\n\"],[41,[30,1,[\"loading\"]],[[[1,\"          \"],[11,0],[24,0,\"tip\"],[17,2],[12],[1,\"\\n            \"],[10,1],[14,0,\"spinner\"],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"tip-content\"],[12],[1,[30,1,[\"reason\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],null,[[\"@validation\"],[[30,1]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[8,[32,1],null,[[\"@value\",\"@max\"],[[30,3],[30,4]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@validation\",\"&attrs\",\"@value\",\"@maxLength\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/validation-tip.js",
      "scope": () => [_inputTip.default, _conditionalCharCounter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ValidationTip;
});