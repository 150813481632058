define("discourse/plugins/discourse-circles/discourse/templates/circle/manage/profile", ["exports", "@glimmer/component", "@ember/helper", "ember-route-template", "discourse/components/plugin-outlet", "discourse/plugins/discourse-circles/discourse/components/circles/form-profile-fields", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _emberRouteTemplate, _pluginOutlet, _formProfileFields, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CircleManageProfile extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <PluginOutlet
            @name="circles-manage-profile"
            @connectorTagName="div"
            @outletArgs={{hash model=@model}}
          >
            <CirclesFormProfileFields
              @model={{@model}}
              @onBeforeSave={{@controller.onBeforeSave}}
              @onAfterSave={{@controller.onAfterSave}}
              @hideCancelBtn={{true}}
            />
          </PluginOutlet>
        
    */
    {
      "id": "gXOOssps",
      "block": "[[[1,\"\\n      \"],[8,[32,0],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"circles-manage-profile\",\"div\",[28,[32,1],null,[[\"model\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@model\",\"@onBeforeSave\",\"@onAfterSave\",\"@hideCancelBtn\"],[[30,1],[30,2,[\"onBeforeSave\"]],[30,2,[\"onAfterSave\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[\"@model\",\"@controller\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circle/manage/profile.js",
      "scope": () => [_pluginOutlet.default, _helper.hash, _formProfileFields.default],
      "isStrictMode": true
    }), this))();
  });
});