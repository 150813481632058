define("discourse/plugins/discourse-circles/discourse/initializers/discourse-circles", ["exports", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/models/category", "discourse/widgets/render-glimmer", "discourse-common/lib/get-owner", "I18n", "discourse/plugins/discourse-circles/discourse/components/circles/add-circles-category-body-class", "discourse/plugins/discourse-circles/discourse/components/circles/user-nav-circles-item", "discourse/plugins/discourse-circles/discourse/components/circles/user-notification-preferences", "@ember/template-factory"], function (_exports, _pluginApi, _utilities, _category, _renderGlimmer, _getOwner, _I18n, _addCirclesCategoryBodyClass, _userNavCirclesItem, _userNotificationPreferences, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-circles";
  var _default = _exports.default = {
    name: PLUGIN_ID,
    initialize(container) {
      const circles = container.lookup("service:circles");
      if (circles.userCanUseCircles) {
        (0, _pluginApi.withPluginApi)("1.3.0", api => {
          addNavigationBarItem(api);
          addCircleMembershipRequestPostDecorator(api);
          addCustomUserSearchOptions(api);
          enhanceTopicListWithCircles(api);
          redirectCategories(api);
          addPostSmallActionIcons(api);
          addNotificationOnCircleMentionUserField(api);
          replaceIcons(api);
          registerCirclesActivityNotificationType(api);
          addCirclesCategoryBodyClass(api);
          mountUserNavCirclesItem(api);
          mountUserNotificationsPreferences(api);
        });
      }
    }
  };
  function addNavigationBarItem(api) {
    api.addNavigationBarItem({
      name: "circles",
      displayName: _I18n.default.t("discourse_circles.index.title"),
      title: _I18n.default.t("discourse_circles.index.title"),
      href: "/circles"
    });
  }
  function addCircleMembershipRequestPostDecorator(api) {
    api.createWidget("post-circle-request", {
      html(attrs) {
        return [new _renderGlimmer.default(this, "div", (0, _templateFactory.createTemplateFactory)(
        /*
          
                    <Circles::MembershipPostRequest
                      @post={{@data.post}}
                      @circleCategoryId={{@data.circle_category_id}}
                      @requestId={{@data.circle_request_id}}
                      @userId={{@data.requested_circle_user_id}} />
        */
        {
          "id": "w13E2jk2",
          "block": "[[[1,\"\\n            \"],[8,[39,0],null,[[\"@post\",\"@circleCategoryId\",\"@requestId\",\"@userId\"],[[30,1,[\"post\"]],[30,1,[\"circle_category_id\"]],[30,1,[\"circle_request_id\"]],[30,1,[\"requested_circle_user_id\"]]]],null]],[\"@data\"],false,[\"circles/membership-post-request\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/initializers/discourse-circles.js",
          "isStrictMode": false
        }), attrs)];
      }
    });
    api.decorateWidget("post-contents:after-cooked", helpers => {
      const post = helpers.getModel();
      const topic = post.topic;
      const membershipRequestDetails = post.post_number === 1 && topic.requested_circle_membership_details ? topic.requested_circle_membership_details : null;
      return membershipRequestDetails ? helpers.attach("post-circle-request", {
        post,
        ...membershipRequestDetails
      }) : null;
    });
  }
  function addCustomUserSearchOptions(api) {
    api.addUserSearchOption("groupIds");
  }
  function enhanceTopicListWithCircles(api) {
    api.modifyClassStatic("model:topic-list", {
      pluginId: PLUGIN_ID,
      topicsFrom(store, result) {
        const topics = this._super(...arguments);
        const circles = result?.topic_list?.circles;
        if (topics && circles) {
          const circlesService = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:circles");

          // add the retrieved circles to the service cache
          circlesService.storeCircles(circles);
          topics.forEach(topic => {
            if (topic.category_id) {
              const topicCircle = circlesService.findCached(topic.category_id);
              if (topicCircle) {
                topic.circle = topicCircle;
              }
            }
          });
        }
        return topics;
      }
    });
  }
  function redirectCategories(api) {
    api.modifyClass(`route:discovery.category`, {
      pluginId: PLUGIN_ID,
      async beforeModel(transition) {
        const category_slug_path_with_id = transition?.to?.params?.category_slug_path_with_id;
        if (!category_slug_path_with_id) {
          return this._super(...arguments);
        }

        // tests the existence of a category matching `category_slug_path_with_id`
        // cached.
        const category = _category.default.findBySlugPathWithID(category_slug_path_with_id);
        if (category) {
          if (category.is_circle) {
            // redirect in case it is a circle
            this.router.replaceWith("circle", category_slug_path_with_id);
          } else {
            return this._super(...arguments);
          }
        }

        // if a category was not found we need to search for a circle matching
        // the category_slug_path_with_id provided
        try {
          const circle = await this.store.find("circle", category_slug_path_with_id);

          // if a circle matching `category_slug_path_with_id` was found
          // redirect to the circle
          if (circle) {
            // TODO cache this result to avoid another call to the end-point
            this.router.replaceWith("circle", category_slug_path_with_id);
          } else {
            return this._super(...arguments);
          }
        } catch (e) {
          if (e.jqXHR?.status === 404 || e.status === 404) {
            // if the circle was a 404 let the transition proceed
            return this._super(...arguments);
          } else {
            throw e;
          }
        }
      }
    });
  }
  function addPostSmallActionIcons(api) {
    api.addPostSmallActionIcon("circle_membership_request_accepted", "dot-circle");
    api.addPostSmallActionIcon("circle_membership_request_denied", "dot-circle");
    api.addPostSmallActionIcon("circle_membership_request_cancelled", "dot-circle");
  }
  function replaceIcons(api) {
    const icon = "dot-circle";
    api.replaceIcon("discourse_circles", icon);
    api.replaceIcon("discourse_circles_public_circle", "globe");
    api.replaceIcon("discourse_circles_private_circle", "lock");
    api.replaceIcon("notification.circles_activity", icon);
    api.replaceIcon("notification.circles_activity_request_membership_pm", icon);
  }
  function registerCirclesActivityNotificationType(api) {
    api.registerNotificationTypeRenderer("circles_activity", NotificationTypeBase => {
      return class extends NotificationTypeBase {
        get label() {
          const data = this.notification.data;
          const {
            circle_activity_type: type
          } = data;
          switch (type) {
            case "request_membership_consolidated":
              return _I18n.default.t(`discourse_circles.notifications.labels.${type}`, {
                count: data.count
              });
            default:
              return (0, _utilities.formatUsername)(data.username);
          }
        }
        get description() {
          const data = this.notification.data;
          const {
            circle_activity_type: type
          } = data;
          return _I18n.default.t(`discourse_circles.notifications.descriptions.${type}`, {
            circle_name: data.circle_name
          });
        }
        get linkTitle() {
          const data = this.notification.data;
          const {
            circle_activity_type: type
          } = data;
          return _I18n.default.t(`discourse_circles.notifications.titles.${type}`);
        }
        get linkHref() {
          const data = this.notification.data;
          const {
            circle_activity_type: type
          } = data;
          switch (type) {
            case "request_membership":
            case "request_membership_consolidated":
              return `/circles/${data.circle_category_id}/requests`;
            default:
              return `/circles/${data.circle_category_id}`;
          }
        }
      };
    });
  }
  const notifyOnCircleMention = "notify_on_circle_mention";
  function addNotificationOnCircleMentionUserField(api) {
    api.addSaveableUserOptionField(notifyOnCircleMention);

    // The following could be added via a plugin API function, but one doesn't exist currently
    // for this route. It's relatively safe to add to `saveAttrNames` manually. discourse-follow
    // does this at the current moment (6/16/23)
    api.modifyClass("controller:preferences/notifications", {
      pluginId: "discourse-circles-notification-preference",
      actions: {
        save() {
          if (!this.saveAttrNames.includes(notifyOnCircleMention)) {
            this.saveAttrNames.push(notifyOnCircleMention);
          }
          this._super(...arguments);
        }
      }
    });
  }
  function addCirclesCategoryBodyClass(api) {
    api.renderInOutlet("above-category-heading", _addCirclesCategoryBodyClass.default);
    api.renderInOutlet("topic-above-post-stream", _addCirclesCategoryBodyClass.default);
  }
  function mountUserNavCirclesItem(api) {
    api.renderInOutlet("user-main-nav", _userNavCirclesItem.default);
  }
  function mountUserNotificationsPreferences(api) {
    api.renderInOutlet("user-preferences-notifications", _userNotificationPreferences.default);
  }
});