define("discourse/plugins/discourse-circles/discourse/controllers/circle-activity-topics", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleActivityTopicsController extends _controller.default {
    loadMore() {
      this.model.loadMore();
    }
    static #_ = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = CircleActivityTopicsController;
});