define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/conditional-char-counter", ["exports", "@glimmer/component", "discourse/components/char-counter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _charCounter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConditionalCharCounter extends _component.default {
    get shouldDisplayCharCounter() {
      return this.args.max > 0;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldDisplayCharCounter}}
          <CharCounter @value={{@value}} @max={{@max}}>
            {{yield}}
          </CharCounter>
        {{else}}
          {{yield}}
        {{/if}}
      
    */
    {
      "id": "luCq/FES",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplayCharCounter\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@value\",\"@max\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@value\",\"@max\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/conditional-char-counter.js",
      "scope": () => [_charCounter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ConditionalCharCounter;
});