define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/image-uploader", ["exports", "discourse/components/uppy-image-uploader", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _uppyImageUploader, _base, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldImageUploader extends _base.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldWireFrame
          @fieldName={{this.fieldName}}
          @title={{this.title}}
          @description={{this.description}}
          @required={{@required}}
          @validation={{@validation}}
        >
          <UppyImageUploader
            class="circles-form-{{@name}}"
            ...attributes
            name={{this.fieldName}}
            @id={{this.fieldName}}
            @imageUrl={{@imageUrl}}
            @type={{@name}}
            @onUploadDone={{@onUploadDone}}
            @onUploadDeleted={{@onUploadDeleted}}
          />
          {{yield}}
        </FormFieldWireFrame>
      
    */
    {
      "id": "2koTB9fy",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@fieldName\",\"@title\",\"@description\",\"@required\",\"@validation\"],[[30,0,[\"fieldName\"]],[30,0,[\"title\"]],[30,0,[\"description\"]],[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,0,[29,[\"circles-form-\",[30,3]]]],[17,4],[16,3,[30,0,[\"fieldName\"]]]],[[\"@id\",\"@imageUrl\",\"@type\",\"@onUploadDone\",\"@onUploadDeleted\"],[[30,0,[\"fieldName\"]],[30,5],[30,3],[30,6],[30,7]]],null],[1,\"\\n      \"],[18,8,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@required\",\"@validation\",\"@name\",\"&attrs\",\"@imageUrl\",\"@onUploadDone\",\"@onUploadDeleted\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/image-uploader.js",
      "scope": () => [_wireFrame.default, _uppyImageUploader.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldImageUploader;
});