define("discourse/plugins/discourse-circles/discourse/routes/circle", ["exports", "@ember/object", "@ember/service", "discourse/mixins/scroll-top", "discourse/routes/discourse", "I18n"], function (_exports, _object, _service, _scrollTop, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "circles", [_service.inject]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentCircle", [_service.inject]))();
    #currentCircle = (() => (dt7948.i(this, "currentCircle"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      if (!this.circles.userCanUseCircles) {
        this.router.replaceWith("/404");
      }
    }
    model(params) {
      return this.store.find("circle", params.slug_path_with_id);
    }
    serialize(model) {
      return {
        name: model?.name?.toLowerCase()
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("model", model);
      controller.set("category", model.category);
      controller.setProperties({
        showCategoryAdmin: model.category.get("can_create_category"),
        canCreateTopic: model.category.get("can_create_topic")
      });
    }
    didTransition() {
      (0, _scrollTop.scrollTop)();
    }
    static #_7 = (() => dt7948.n(this.prototype, "didTransition", [_object.action]))();
    clickCreateTopicButton() {
      if (this.currentUser?.has_topic_draft) {
        this.composer.openNewTopic({
          preferDraft: true
        });
      } else {
        this.#createNewTopic();
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "clickCreateTopicButton", [_object.action]))();
    #createNewTopic() {
      if (this.currentCircle.userCanCreateTopic) {
        this.composer.openNewTopic({
          category: this.controller.model?.category
        });
      } else {
        if (this.currentCircle.userIsMember) {
          this.dialog.alert(_I18n.default.t("discourse_circles.circle.errors.cant_create_topic"));
        } else {
          this.dialog.alert(_I18n.default.t("discourse_circles.circle.errors.join_before_create_topic"));
        }
      }
    }
  }
  _exports.default = CircleRoute;
});