define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/dropdown", ["exports", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/combo-box", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _dropdownSelectBox, _comboBox, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldDropdown extends _comboBox.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldWireFrame
          @fieldName={{this.fieldName}}
          @title={{this.title}}
          @description={{this.description}}
          @required={{@required}}
          @validation={{@validation}}
        >
          <DropdownSelectBox
            class="circles-form-{{@name}}"
            ...attributes
            name={{this.fieldName}}
            @nameProperty={{this.nameProperty}}
            @valueProperty={{this.valueProperty}}
            @value={{@value}}
            @none={{@noneI18n}}
            @content={{@content}}
            @onChange={{@onChange}}
            @options={{this.options}}
          />
          {{yield}}
        </FormFieldWireFrame>
      
    */
    {
      "id": "sWiyNElT",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@fieldName\",\"@title\",\"@description\",\"@required\",\"@validation\"],[[30,0,[\"fieldName\"]],[30,0,[\"title\"]],[30,0,[\"description\"]],[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,0,[29,[\"circles-form-\",[30,3]]]],[17,4],[16,3,[30,0,[\"fieldName\"]]]],[[\"@nameProperty\",\"@valueProperty\",\"@value\",\"@none\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"nameProperty\"]],[30,0,[\"valueProperty\"]],[30,5],[30,6],[30,7],[30,8],[30,0,[\"options\"]]]],null],[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@required\",\"@validation\",\"@name\",\"&attrs\",\"@value\",\"@noneI18n\",\"@content\",\"@onChange\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/dropdown.js",
      "scope": () => [_wireFrame.default, _dropdownSelectBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldDropdown;
});