define("discourse/plugins/discourse-circles/discourse/components/circles/members-dropdown-actions", ["exports", "@ember/object", "@ember/utils", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _object, _utils, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    pluginApiIdentifiers: ["circles-members-dropdown-actions"],
    classNames: ["circles-members-dropdown-actions"],
    selectKitOptions: {
      icon: "wrench",
      showFullTitle: false
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.set("selectKit.isHidden", (0, _utils.isEmpty)(this.content));
    },
    content: (0, _object.computed)(function () {
      return [{
        id: "removeMember",
        name: _I18n.default.t("discourse_circles.circle.members.remove_member"),
        description: _I18n.default.t("discourse_circles.circle.members.remove_member_description", {
          username: this.get("member.username")
        }),
        icon: "user-times",
        disabled: this.get("member.owner")
      }].filter(item => !item.disabled);
    })
  });
});