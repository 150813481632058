define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", ["exports", "@glimmer/component", "@ember/helper", "discourse/components/plugin-outlet", "discourse/helpers/concat-class", "discourse/plugins/discourse-circles/discourse/lib/html-helpers", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _pluginOutlet, _concatClass, _htmlHelpers, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldWireFrame extends _component.default {
    get classNames() {
      return ["circles-search-filter", "circles-search-filter__" + (0, _htmlHelpers.stringToClassname)(this.args.filter.name)].join(" ");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <PluginOutlet
          @name="circles-search-filter"
          @outletArgs={{hash
            classNames=this.classNames
            controller=@controller
            filter=@filter
            value=@value
          }}
        >
          <div
            class={{concatClass this.classNames}}
            ...attributes
            data-filter-name={{@filter.name}}
            data-filter-type={{@filter.type}}
          >
            {{yield}}
          </div>
        </PluginOutlet>
      
    */
    {
      "id": "PYAkeKjr",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-search-filter\",[28,[32,1],null,[[\"classNames\",\"controller\",\"filter\",\"value\"],[[30,0,[\"classNames\"]],[30,1],[30,2],[30,3]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,0],[16,0,[28,[32,2],[[30,0,[\"classNames\"]]],null]],[17,4],[16,\"data-filter-name\",[30,2,[\"name\"]]],[16,\"data-filter-type\",[30,2,[\"type\"]]],[12],[1,\"\\n        \"],[18,5,null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@controller\",\"@filter\",\"@value\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame.js",
      "scope": () => [_pluginOutlet.default, _helper.hash, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldWireFrame;
});