define("discourse/plugins/discourse-circles/discourse/routes/circles-new", ["exports", "@ember/service", "discourse/routes/discourse", "I18n", "discourse/plugins/discourse-circles/discourse/models/circle"], function (_exports, _service, _discourse, _I18n, _circle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesNewRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "circles", [_service.inject]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    showFooter = true;
    titleToken() {
      return _I18n.default.t("discourse_circles.new.title_token");
    }
    async beforeModel() {
      await this.circles.reloadCustomFields();
    }
    async model() {
      return this.store.createRecord("circle", _circle.default.attributesDefaults());
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("model", model);
    }
  }
  _exports.default = CirclesNewRoute;
});