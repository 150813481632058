define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/editor", ["exports", "@ember/component", "discourse/components/d-editor", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/validation-tip", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", "@ember/template-factory"], function (_exports, _component, _dEditor, _base, _validationTip, _wireFrame, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldEditor extends _base.default {
    get useRichEditor() {
      return !this.args.disableRichEditor;
    }
    get textAreaValidation() {
      // use the standard placement in the controls wireframe only if we're not using the rich editor
      return this.useRichEditor ? null : this.args.validation;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldWireFrame
          @fieldName={{this.fieldName}}
          @title={{this.title}}
          @description={{this.description}}
          @required={{@required}}
        >
          {{#if this.useRichEditor}}
            <DEditor
              class="circles-form-{{@name}}"
              ...attributes
              name={{this.fieldName}}
              @autofocus={{@autofocus}}
              @placeholder={{this.placeholderI18n}}
              @value={{@value}}
            >
              <ValidationTip
                @validation={{@validation}}
                @value={{@value}}
                @maxLength={{@maxLength}}
              />
            </DEditor>
          {{else}}
            <Textarea
              class="circles-form-{{@name}}"
              ...attributes
              autofocus={{@autofocus}}
              name={{this.fieldName}}
              placeholder={{this.placeholder}}
              @value={{@value}}
            />
            <ValidationTip
              @validation={{@validation}}
              @value={{@value}}
              @maxLength={{@maxLength}}
            />
          {{/if}}
          {{yield}}
        </FormFieldWireFrame>
      
    */
    {
      "id": "qHIHtQQ6",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@fieldName\",\"@title\",\"@description\",\"@required\"],[[30,0,[\"fieldName\"]],[30,0,[\"title\"]],[30,0,[\"description\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"useRichEditor\"]],[[[1,\"        \"],[8,[32,1],[[16,0,[29,[\"circles-form-\",[30,2]]]],[17,3],[16,3,[30,0,[\"fieldName\"]]]],[[\"@autofocus\",\"@placeholder\",\"@value\"],[[30,4],[30,0,[\"placeholderI18n\"]],[30,5]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@validation\",\"@value\",\"@maxLength\"],[[30,6],[30,5],[30,7]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,3],[[16,0,[29,[\"circles-form-\",[30,2]]]],[17,3],[16,\"autofocus\",[30,4]],[16,3,[30,0,[\"fieldName\"]]],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\"],[[30,5]]],null],[1,\"        \"],[8,[32,2],null,[[\"@validation\",\"@value\",\"@maxLength\"],[[30,6],[30,5],[30,7]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[18,8,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@required\",\"@name\",\"&attrs\",\"@autofocus\",\"@value\",\"@validation\",\"@maxLength\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/editor.js",
      "scope": () => [_wireFrame.default, _dEditor.default, _validationTip.default, _component.Textarea],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldEditor;
});