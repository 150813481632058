define("discourse/plugins/discourse-circles/discourse/routes/circle-activity-posts", ["exports", "discourse/routes/group-activity-posts", "I18n"], function (_exports, _groupActivityPosts, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const type = "posts";
  const GroupPostsRoute = (0, _groupActivityPosts.buildGroupPage)("posts");
  class CircleActivityPostsRoute extends GroupPostsRoute {
    titleToken() {
      const filterText = _I18n.default.t("filters.latest.title");
      return _I18n.default.t("discourse_circles.circle.activity.title_token.posts", {
        circle_name: this.modelFor("circle").name,
        filter: filterText
      });
    }
    model() {
      const circleModel = this.modelFor("circle");
      const categoryId = circleModel.category_id;
      const group = circleModel.member_group;
      return group.findPosts({
        type,
        categoryId
      });
    }
  }
  _exports.default = CircleActivityPostsRoute;
});