define("discourse/plugins/discourse-circles/discourse/templates/admin/plugins/circles", ["exports", "@glimmer/component", "@ember/routing", "ember-route-template", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/discourse-circles/discourse/routes/admin-plugins-circles", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _emberRouteTemplate, _concatClass, _i18n, _adminPluginsCircles, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class AdminPluginsCircles extends _component.default {
    get navItems() {
      return _adminPluginsCircles.TABS;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <div class="admin-nav admin-site-settings-category-nav pull-left">
            <ul class="nav nav-stacked">
              {{#each this.navItems as |item|}}
                <li
                  class={{concatClass
                    "admin-site-settings-category-nav__item"
                    item.nameKey
                  }}
                >
                  <LinkTo
                    class={{item.key}}
                    title={{i18n item.label}}
                    @route={{item.route}}
                  >
                    {{i18n item.label}}
                  </LinkTo>
                </li>
              {{/each}}
            </ul>
          </div>
    
          <div class="admin-detail pull-left mobile-closed">
            {{outlet}}
          </div>
        
    */
    {
      "id": "LhhhwhXp",
      "block": "[[[1,\"\\n      \"],[10,0],[14,0,\"admin-nav admin-site-settings-category-nav pull-left\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"nav nav-stacked\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"navItems\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[28,[32,0],[\"admin-site-settings-category-nav__item\",[30,1,[\"nameKey\"]]],null]],[12],[1,\"\\n              \"],[8,[32,1],[[16,0,[30,1,[\"key\"]]],[16,\"title\",[28,[32,2],[[30,1,[\"label\"]]],null]]],[[\"@route\"],[[30,1,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[32,2],[[30,1,[\"label\"]]],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"admin-detail pull-left mobile-closed\"],[12],[1,\"\\n        \"],[46,[28,[31,3],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"item\"],false,[\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/admin/plugins/circles.js",
      "scope": () => [_concatClass.default, _routing.LinkTo, _i18n.default],
      "isStrictMode": true
    }), this))();
  });
});