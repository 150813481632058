define("discourse/plugins/discourse-circles/discourse/routes/circle-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel(transition) {
      const circleModel = this.modelFor("circle");
      const target = circleModel.can_read_topics ? "circle.activity" : "circle.about";
      if (transition.urlMethod === "replace") {
        this.router.replaceWith(target);
      } else {
        this.router.transitionTo(target);
      }
    }
  }
  _exports.default = CircleIndexRoute;
});