define("discourse/plugins/discourse-circles/discourse/controllers/circles-new", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesNewController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    onAfterSave() {
      const {
        slug_path_with_id
      } = this.model;
      this.router.replaceWith("circle", slug_path_with_id);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onAfterSave", [_object.action]))();
  }
  _exports.default = CirclesNewController;
});