define("discourse/plugins/discourse-circles/discourse/templates/user/circles", ["exports", "@glimmer/component", "@ember/helper", "ember-route-template", "discourse/components/d-navigation-item", "discourse/components/horizontal-overflow-nav", "discourse/components/plugin-outlet", "discourse/helpers/body-class", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _emberRouteTemplate, _dNavigationItem, _horizontalOverflowNav, _pluginOutlet, _bodyClass, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class UserCircles extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          {{bodyClass "user-circles-page"}}
    
          <div
            class="user-navigation user-navigation-secondary circles-user-navigation"
          >
            <HorizontalOverflowNav
              class="activity-nav"
              @desktopClass="action-list activity-list nav-stacked"
            >
              {{#each @controller.types as |tab|}}
                <DNavigationItem
                  @route={{tab.route}}
                  @model={{tab.model}}
                  @ariaCurrentContext="subNav"
                  class="user-nav__circles-{{tab.name}}"
                  data-name="circles-{{tab.name}}"
                >
                  {{#if tab.icon}}{{dIcon tab.icon}}{{/if}}
                  {{tab.title}}
                  {{#if tab.count}}<span class="count">({{tab.count}})</span>{{/if}}
                </DNavigationItem>
              {{/each}}
            </HorizontalOverflowNav>
          </div>
          <section class="user-content user-circles-content">
            <PluginOutlet
              @name="user-circles-content"
              @outletArgs={{hash model=@controller.model}}
            >
              {{outlet}}
            </PluginOutlet>
          </section>
        
    */
    {
      "id": "ugVf8Is9",
      "block": "[[[1,\"\\n      \"],[1,[28,[32,0],[\"user-circles-page\"],null]],[1,\"\\n\\n      \"],[10,0],[14,0,\"user-navigation user-navigation-secondary circles-user-navigation\"],[12],[1,\"\\n        \"],[8,[32,1],[[24,0,\"activity-nav\"]],[[\"@desktopClass\"],[\"action-list activity-list nav-stacked\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"types\"]]],null]],null],null,[[[1,\"            \"],[8,[32,2],[[16,0,[29,[\"user-nav__circles-\",[30,2,[\"name\"]]]]],[16,\"data-name\",[29,[\"circles-\",[30,2,[\"name\"]]]]]],[[\"@route\",\"@model\",\"@ariaCurrentContext\"],[[30,2,[\"route\"]],[30,2,[\"model\"]],\"subNav\"]],[[\"default\"],[[[[1,\"\\n              \"],[41,[30,2,[\"icon\"]],[[[1,[28,[32,3],[[30,2,[\"icon\"]]],null]]],[]],null],[1,\"\\n              \"],[1,[30,2,[\"title\"]]],[1,\"\\n              \"],[41,[30,2,[\"count\"]],[[[10,1],[14,0,\"count\"],[12],[1,\"(\"],[1,[30,2,[\"count\"]]],[1,\")\"],[13]],[]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"user-content user-circles-content\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@name\",\"@outletArgs\"],[\"user-circles-content\",[28,[32,5],null,[[\"model\"],[[30,1,[\"model\"]]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[46,[28,[31,4],null,null],null,null,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"@controller\",\"tab\"],false,[\"each\",\"-track-array\",\"if\",\"component\",\"-outlet\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/user/circles.js",
      "scope": () => [_bodyClass.default, _horizontalOverflowNav.default, _dNavigationItem.default, _dIcon.default, _pluginOutlet.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  });
});