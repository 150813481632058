define("discourse/plugins/discourse-circles/discourse/services/current-circle", ["exports", "@ember/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Circle extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "circles", [_service.inject]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get viewingCircle() {
      return this.#isCircleRoute(this.router.currentRoute);
    }
    get slugPathWithId() {
      if (!this.viewingCircle) {
        return;
      }
      return this.#extractSlugPathWithidFromRoute(this.router.currentRoute);
    }
    get model() {
      if (!this.viewingCircle) {
        return;
      }
      return (0, _application.getOwner)(this).lookup("route:circle").modelFor("circle");
    }
    get userCanCreateTopic() {
      return this.circles.userCanCreateTopic(this.model);
    }
    get userIsMember() {
      return this.circles.userIsMember(this.model);
    }
    #isCircleRoute(currentRoute) {
      return !!(currentRoute?.name?.startsWith("circle.") || currentRoute.localName === "circle");
    }
    #extractSlugPathWithidFromRoute(routeInfo) {
      if (!this.#isCircleRoute(routeInfo)) {
        return;
      }
      if (routeInfo.localName === "circle") {
        return routeInfo?.params?.slug_path_with_id;
      }
      return this.#extractSlugPathWithidFromRoute(routeInfo.parent);
    }
  }
  _exports.default = Circle;
});