define("discourse/plugins/discourse-circles/discourse/controllers/circle-members", ["exports", "@ember/object", "@ember/service", "discourse/controllers/group-index", "discourse-common/utils/decorators", "I18n"], function (_exports, _object, _service, _groupIndex, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleMembersController extends _groupIndex.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    circleMembershipType = "members";
    actOnSelection(selection, actionId) {
      if (!selection || selection.length === 0) {
        return;
      }
      switch (actionId) {
        case "removeMembers":
          const owners = selection.filter(user => user.owner);
          if (owners.length > 0) {
            this.dialog.alert(_I18n.default.t(`discourse_circles.circle.${this.circleMembershipType}.cannot_remove_owner`, {
              usernames: owners.map(user => user.username).join(", "),
              count: owners.length
            }));
            return;
          }
          break;
        case "makeOwners":
        case "removeOwners":
          // feature disabled for Circles groups
          return;
      }
      super.actOnSelection(...arguments);
    }
    static #_2 = (() => dt7948.n(this.prototype, "actOnSelection", [_object.action]))();
    isBulkSelected(user) {
      return this.bulkSelection?.some(u => u.id === user.id);
    }
    static #_3 = (() => dt7948.n(this.prototype, "isBulkSelected", [_decorators.bind]))();
    removeMember(user) {
      if (user.owner) {
        this.dialog.alert(_I18n.default.t(`discourse_circles.circle.${this.circleMembershipType}.cannot_remove_owner`, {
          usernames: user.username,
          count: 1
        }));
        return;
      }
      super.removeMember(...arguments);
    }
    static #_4 = (() => dt7948.n(this.prototype, "removeMember", [_object.action]))();
    makeOwner() {
      // feature disabled for Circles groups
    }
    static #_5 = (() => dt7948.n(this.prototype, "makeOwner", [_object.action]))();
    removeOwner() {
      // feature disabled for Circles groups
    }

    // overriding because we need to keep the bulk selection if the user navigates to other tabs
    static #_6 = (() => dt7948.n(this.prototype, "removeOwner", [_object.action]))();
    selectMember(member, e) {
      this.set("bulkSelection", this.bulkSelection || []);

      // to prevent duplicates in the bulk selection we remove the user if it's already there
      // comparing the id. this is necessary because pushObject won't deduplicate if the user
      // navigated to another tab and then returned as the objects will be different because
      // the data may have been reloaded
      const selection = this.bulkSelection.reject(u => u.id === member.id);

      // if the checkbox is checked we add the user to the bulk selection
      // otherwise we already remover the user from the bulk selection in the step above
      if (e.target.checked) {
        selection.pushObject(member);
      }
      this.set("bulkSelection", selection);
    }
    static #_7 = (() => dt7948.n(this.prototype, "selectMember", [_object.action]))();
  }
  _exports.default = CircleMembersController;
});