define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/text", ["exports", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/text", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/base", "@ember/component", "@ember/template-factory"], function (_exports, _text, _base, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomFieldText extends _base.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldText
          ...attributes
          @name={{@field.name}}
          @title={{this.title}}
          @showTitle={{this.shouldDisplayTitle}}
          @description={{this.description}}
          @showDescription={{this.shouldDisplayDescription}}
          @placeholder={{this.placeholder}}
          @showPlaceholder={{this.shouldDisplayPlaceholder}}
          @required={{@field.required}}
          @autofocus={{@autofocus}}
          @value={{@value}}
          @onChange={{@onChange}}
          @maxLength={{this.customFieldMaxLength}}
          @validation={{@validation}}
        >
          {{yield}}
        </FormFieldText>
      
    */
    {
      "id": "Lmu7Urda",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@name\",\"@title\",\"@showTitle\",\"@description\",\"@showDescription\",\"@placeholder\",\"@showPlaceholder\",\"@required\",\"@autofocus\",\"@value\",\"@onChange\",\"@maxLength\",\"@validation\"],[[30,2,[\"name\"]],[30,0,[\"title\"]],[30,0,[\"shouldDisplayTitle\"]],[30,0,[\"description\"]],[30,0,[\"shouldDisplayDescription\"]],[30,0,[\"placeholder\"]],[30,0,[\"shouldDisplayPlaceholder\"]],[30,2,[\"required\"]],[30,3],[30,4],[30,5],[30,0,[\"customFieldMaxLength\"]],[30,6]]],[[\"default\"],[[[[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@field\",\"@autofocus\",\"@value\",\"@onChange\",\"@validation\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/text.js",
      "scope": () => [_text.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomFieldText;
});