define("discourse/plugins/discourse-circles/discourse/controllers/circle-about", ["exports", "@ember/controller", "@ember/object", "I18n", "discourse/plugins/discourse-circles/discourse/components/circles/card/stats"], function (_exports, _controller, _object, _I18n, _stats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleActivityTopicsController extends _controller.default {
    get featuredMembers() {
      return this.model.featured_members;
    }
    get activityStats() {
      return _stats.activityTypes.filter(type => type === "all_time" || this.model.category[`topics_${type}`] > 0).map(stat => _I18n.default.t(`discourse_circles.circle.topics_stats.${stat}`, {
        count: this.model.category[`topics_${stat}`]
      }));
    }
    static #_ = (() => dt7948.n(this.prototype, "activityStats", [(0, _object.computed)(..._stats.activityTypes.map(type => `args.model.category.topics_${type}`))]))();
    get createdAt() {
      return moment(this.model.created_at).format(_I18n.default.t("dates.full_with_year_no_time"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "createdAt", [(0, _object.computed)("model.created_at")]))();
  }
  _exports.default = CircleActivityTopicsController;
});