define("discourse/plugins/discourse-circles/discourse/routes/circle-staff", ["exports", "I18n", "discourse/plugins/discourse-circles/discourse/routes/circle-members"], function (_exports, _I18n, _circleMembers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleStaffRoute extends _circleMembers.default {
    templateName = "circle-members";
    titleToken() {
      return _I18n.default.t("discourse_circles.circle.staff.title_token", {
        circle_name: this.modelFor("circle").name
      });
    }
    get mode() {
      return "staff";
    }
    get group() {
      return this.circle?.staff_group;
    }
  }
  _exports.default = CircleStaffRoute;
});