define("discourse/plugins/discourse-circles/discourse/components/circles/card/badges", ["exports", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-circles/discourse/components/circles/privacy-status", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _i18n, _privacyStatus, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="circles-card__badges" ...attributes>
      <CirclesPrivacyStatus
        class="badge badge-notification"
        @model={{@model}}
        @extendedName={{true}}
      />
      {{#if @model.is_circle_owner}}
        <div class="circles-owned-status badge badge-notification">
          {{icon "shield-alt"}}
          {{i18n "discourse_circles.circle.you_are_the_owner"}}
        </div>
      {{/if}}
    </div>
  
  */
  {
    "id": "q/uUciLo",
    "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"circles-card__badges\"],[17,1],[12],[1,\"\\n    \"],[8,[32,0],[[24,0,\"badge badge-notification\"]],[[\"@model\",\"@extendedName\"],[[30,2],true]],null],[1,\"\\n\"],[41,[30,2,[\"is_circle_owner\"]],[[[1,\"      \"],[10,0],[14,0,\"circles-owned-status badge badge-notification\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"shield-alt\"],null]],[1,\"\\n        \"],[1,[28,[32,2],[\"discourse_circles.circle.you_are_the_owner\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@model\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/card/badges.js",
    "scope": () => [_privacyStatus.default, _dIcon.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "badges"));
});