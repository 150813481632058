define("discourse/plugins/discourse-circles/discourse/routes/admin-plugins-circles", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TABS = void 0;
  const TABS = _exports.TABS = [{
    key: "custom-fields",
    route: "adminPlugins.circles.custom-fields",
    label: "discourse_circles.admin.custom_fields.title"
  }];
  class AdminPluginsCirclesRoute extends _discourse.default {}
  _exports.default = AdminPluginsCirclesRoute;
});