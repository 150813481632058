define("discourse/plugins/discourse-circles/discourse/controllers/circle-staff", ["exports", "discourse/plugins/discourse-circles/discourse/controllers/circle-members"], function (_exports, _circleMembers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleStaffController extends _circleMembers.default {
    circleMembershipType = "staff";
  }
  _exports.default = CircleStaffController;
});