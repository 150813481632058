define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/text", ["exports", "discourse/components/text-field", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _textField, _base, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldText extends _base.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldWireFrame
          @fieldName={{this.fieldName}}
          @title={{this.title}}
          @description={{this.description}}
          @required={{@required}}
          @maxLength={{@maxLength}}
          @validation={{@validation}}
          @value={{@value}}
        >
          <TextField
            autocomplete="off"
            class="circles-form-{{@name}} no-blur"
            ...attributes
            name={{this.fieldName}}
            @autofocus={{@autofocus}}
            @placeholder={{this.placeholder}}
            @value={{@value}}
            @onChange={{@onChange}}
          />
          {{yield}}
        </FormFieldWireFrame>
      
    */
    {
      "id": "By+gcaY+",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@fieldName\",\"@title\",\"@description\",\"@required\",\"@maxLength\",\"@validation\",\"@value\"],[[30,0,[\"fieldName\"]],[30,0,[\"title\"]],[30,0,[\"description\"]],[30,1],[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[24,\"autocomplete\",\"off\"],[16,0,[29,[\"circles-form-\",[30,5],\" no-blur\"]]],[17,6],[16,3,[30,0,[\"fieldName\"]]]],[[\"@autofocus\",\"@placeholder\",\"@value\",\"@onChange\"],[[30,7],[30,0,[\"placeholder\"]],[30,4],[30,8]]],null],[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@required\",\"@maxLength\",\"@validation\",\"@value\",\"@name\",\"&attrs\",\"@autofocus\",\"@onChange\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/text.js",
      "scope": () => [_wireFrame.default, _textField.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldText;
});