define("discourse/plugins/discourse-circles/discourse/templates/circle/manage/advanced", ["exports", "@glimmer/component", "@ember/template", "ember-route-template", "discourse-common/helpers/i18n", "discourse/plugins/discourse-circles/discourse/components/circles/delete-button", "discourse/plugins/discourse-circles/discourse/components/circles/manage-ownership-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template, _emberRouteTemplate, _i18n, _deleteButton, _manageOwnershipButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CircleManageProfile extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <section
            class="circles-manage-advanced-section circles-manage-transfer-ownership"
          >
            <h2>
              {{i18n "discourse_circles.circle.manage.advanced.ownership.title"}}
            </h2>
            <p>
              {{i18n
                "discourse_circles.circle.manage.advanced.ownership.add.disclaimer"
              }}
            </p>
            <CircleManageOwnershipButton @model={{@model}} @mode="add" />
    
            <p>
              {{i18n
                "discourse_circles.circle.manage.advanced.ownership.remove.disclaimer"
              }}
            </p>
            <CircleManageOwnershipButton @model={{@model}} @mode="remove" />
    
            <p>
              {{i18n
                "discourse_circles.circle.manage.advanced.ownership.transfer.disclaimer"
              }}
            </p>
            <CircleManageOwnershipButton @model={{@model}} @mode="transfer" />
          </section>
    
          <section
            class="circles-manage-advanced-section circles-manage-delete-circle"
          >
            <h2>
              {{i18n "discourse_circles.circle.manage.advanced.delete.title"}}
            </h2>
            <p>
              {{htmlSafe
                (i18n
                  "discourse_circles.circle.manage.advanced.delete.disclaimer"
                  name=@model.name
                )
              }}
            </p>
            <CircleDeleteButton @model={{@model}} />
          </section>
        
    */
    {
      "id": "M8NmttVw",
      "block": "[[[1,\"\\n      \"],[10,\"section\"],[14,0,\"circles-manage-advanced-section circles-manage-transfer-ownership\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.circle.manage.advanced.ownership.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.circle.manage.advanced.ownership.add.disclaimer\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@model\",\"@mode\"],[[30,1],\"add\"]],null],[1,\"\\n\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.circle.manage.advanced.ownership.remove.disclaimer\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@model\",\"@mode\"],[[30,1],\"remove\"]],null],[1,\"\\n\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.circle.manage.advanced.ownership.transfer.disclaimer\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@model\",\"@mode\"],[[30,1],\"transfer\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,\"section\"],[14,0,\"circles-manage-advanced-section circles-manage-delete-circle\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.circle.manage.advanced.delete.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,0],[\"discourse_circles.circle.manage.advanced.delete.disclaimer\"],[[\"name\"],[[30,1,[\"name\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,3],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"@model\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circle/manage/advanced.js",
      "scope": () => [_i18n.default, _manageOwnershipButton.default, _template.htmlSafe, _deleteButton.default],
      "isStrictMode": true
    }), this))();
  });
});