define("discourse/plugins/discourse-circles/discourse/routes/user-circles-type", ["exports", "discourse/routes/discourse", "I18n"], function (_exports, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserCirclesTypeRoute extends _discourse.default {
    controllerName = "circles-index";
    templateName = "circles-index";
    queryParams = {
      type: {
        refreshModel: true,
        replace: true
      },
      filter: {
        refreshModel: true
      }
    };
    titleToken() {
      const isSelf = this.currentUser?.username === this.modelFor("user.circles").username;
      const type = this.modelFor("user.circles.type").type_id;
      const localeKey = isSelf ? type : `${type}-else`;
      return _I18n.default.t(`discourse_circles.index.user.types.${localeKey}.description`);
    }
    model(params) {
      return params;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      const {
        type_id,
        ...loadParams
      } = model;
      const user = this.modelFor("user");
      controller.type = null;
      controller.loadCircles({
        ...loadParams,
        type: type_id,
        username: user.username
      });
    }
  }
  _exports.default = UserCirclesTypeRoute;
});