define("discourse/plugins/discourse-circles/discourse/components/circles/user-dropdown", ["exports", "select-kit/components/combo-box"], function (_exports, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    pluginApiIdentifiers: ["circles-user-dropdown"],
    classNames: ["circles-user-dropdown"],
    valueProperty: "username",
    labelProperty: "username",
    selectKitOptions: {
      caretDownIcon: "caret-right",
      caretUpIcon: "caret-down"
    },
    modifyComponentForRow() {
      return "user-chooser/user-row";
    }
  });
});