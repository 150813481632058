define("discourse/plugins/discourse-circles/discourse/components/circles/delete-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleDeleteButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "destroying", [_tracking.tracked], function () {
      return false;
    }))();
    #destroying = (() => (dt7948.i(this, "destroying"), void 0))();
    get canDelete() {
      return !!(this.args.model?.category_id && this.args.model?.can_delete);
    }
    destroyCircle() {
      this.destroying = true;
      const model = this.args.model;
      let message = _I18n.default.t("discourse_circles.circle.confirm_delete.question", {
        name: model.name
      });
      if (model.has_messages && model.message_count > 0) {
        message = _I18n.default.t("discourse_circles.circle.confirm_delete.question_with_messages", {
          count: model.message_count
        });
      }
      return this.dialog.confirm({
        title: _I18n.default.t("discourse_circles.circle.confirm_delete.title", {
          name: model.name
        }),
        message,
        confirmButtonLabel: "discourse_circles.circle.confirm_delete.confirm",
        cancelButtonLabel: "cancel",
        didConfirm: () => {
          let deleted = false;
          model.destroy().then(() => deleted = true).catch(error => {
            // eslint-disable-next-line no-console
            console.error(error);
            this.dialog.alert(_I18n.default.t("discourse_circles.circle.errors.delete"));
          }).then(() => {
            // the route transition must be chained after the catch because
            // Ember can abort the original transition to redirect the page
            // using query params. in this case we can ignore the error
            // TransitionAborted
            if (deleted) {
              this.args.onAfterDelete?.() || this.router.transitionTo("circles.index");
            }
          }).finally(() => this.destroying = false);
        },
        didCancel: () => this.destroying = false
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "destroyCircle", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.canDelete}}
          <DButton
            @action={{this.destroyCircle}}
            class="circles-delete-button btn-danger"
            @disabled={{this.destroying}}
            @icon="trash-alt"
            @label="discourse_circles.circle.actions.delete"
          />
        {{/if}}
      
    */
    {
      "id": "RQs9fgbb",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"canDelete\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"circles-delete-button btn-danger\"]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\"],[[30,0,[\"destroyCircle\"]],[30,0,[\"destroying\"]],\"trash-alt\",\"discourse_circles.circle.actions.delete\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/delete-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CircleDeleteButton;
});