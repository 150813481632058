define("discourse/plugins/discourse-circles/discourse/adapters/circle-custom-field", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleFieldAdapter extends _rest.default {
    basePath() {
      return "/admin/plugins/circles/";
    }
    apiNameFor() {
      return "custom-field";
    }
    pathFor(store, type, findArgs) {
      let path = this.basePath(store, type, findArgs) + store.pluralize(this.apiNameFor(type));
      return this.appendQueryParams(path, findArgs);
    }
  }
  _exports.default = CircleFieldAdapter;
});