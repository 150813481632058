define("discourse/plugins/discourse-circles/discourse/components/circles/view/navigation-tabs", ["exports", "@ember/helper", "@ember/routing", "discourse/components/mobile-nav", "discourse/components/plugin-outlet", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _routing, _mobileNav, _pluginOutlet, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Tab = void 0;
  const CirclesViewNavigationTabs = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <PluginOutlet
      @name="circles-view-navigation-tabs"
      @outletArgs={{hash model=@model tabs=@tabs}}
    >
      <MobileNav @desktopClass="nav nav-pills" class="circles-nav">
        {{#each @tabs as |tab|}}
          <PluginOutlet
            @name="circles-view-navigation-tab-item"
            @outletArgs={{hash model=@model tab=@tab tabs=@tabs}}
          >
            <Tab @tab={{tab}} @model={{@model}} />
          </PluginOutlet>
        {{/each}}
      </MobileNav>
    </PluginOutlet>
  
  */
  {
    "id": "LV0AVgyC",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-view-navigation-tabs\",[28,[32,1],null,[[\"model\",\"tabs\"],[[30,1],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[32,2],[[24,0,\"circles-nav\"]],[[\"@desktopClass\"],[\"nav nav-pills\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-view-navigation-tab-item\",[28,[32,1],null,[[\"model\",\"tab\",\"tabs\"],[[30,1],[30,4],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],null,[[\"@tab\",\"@model\"],[[30,3],[30,1]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@model\",\"@tabs\",\"tab\",\"@tab\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/view/navigation-tabs.js",
    "scope": () => [_pluginOutlet.default, _helper.hash, _mobileNav.default, Tab],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "navigation-tabs:CirclesViewNavigationTabs"));
  const Tab = _exports.Tab = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <li
      class={{if @tab.options.showNotification "circles-show-notification"}}
      data-tab-name={{@tab.name}}
    >
      <LinkTo
        @route={{@tab.route}}
        @model={{@model}}
        title={{@tab.title}}
        class="circles-tab-{{@tab.name}}"
      >
        {{#if @tab.icon}}{{dIcon @tab.icon}}{{/if}}
        <span class="circles-nav-title">{{@tab.title}}</span>
        {{#if @tab.options.count}}
          <span class="circles-nav-count">{{@tab.options.count}}</span>
        {{/if}}
      </LinkTo>
    </li>
  
  */
  {
    "id": "xikfx66w",
    "block": "[[[1,\"\\n  \"],[10,\"li\"],[15,0,[52,[30,1,[\"options\",\"showNotification\"]],\"circles-show-notification\"]],[15,\"data-tab-name\",[30,1,[\"name\"]]],[12],[1,\"\\n    \"],[8,[32,0],[[16,\"title\",[30,1,[\"title\"]]],[16,0,[29,[\"circles-tab-\",[30,1,[\"name\"]]]]]],[[\"@route\",\"@model\"],[[30,1,[\"route\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[41,[30,1,[\"icon\"]],[[[1,[28,[32,1],[[30,1,[\"icon\"]]],null]]],[]],null],[1,\"\\n      \"],[10,1],[14,0,\"circles-nav-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"options\",\"count\"]],[[[1,\"        \"],[10,1],[14,0,\"circles-nav-count\"],[12],[1,[30,1,[\"options\",\"count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@tab\",\"@model\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/view/navigation-tabs.js",
    "scope": () => [_routing.LinkTo, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "navigation-tabs:Tab"));
  var _default = _exports.default = CirclesViewNavigationTabs;
});