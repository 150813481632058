define("discourse/plugins/discourse-circles/discourse/components/circles/members-dropdown-bulk-actions", ["exports", "@ember/object", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _object, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    pluginApiIdentifiers: ["circles-members-dropdown-bulk-actions"],
    classNames: ["circles-members-dropdown-bulk-actions"],
    selectKitOptions: {
      icon: "wrench",
      showFullTitle: false
    },
    content: (0, _object.computed)("bulkSelection.[]", function () {
      return [{
        id: "removeMembers",
        name: _I18n.default.t("discourse_circles.circle.members.remove_members"),
        description: _I18n.default.t("discourse_circles.circle.members.remove_members_description"),
        icon: "user-times"
      }];
    })
  });
});