define("discourse/plugins/discourse-circles/discourse/adapters/circle", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleAdapter extends _rest.default {
    primaryKey = "category_id";
    appendQueryParams(path, findArgs) {
      let extension = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ".json";
      if (typeof findArgs === "string" && findArgs.indexOf("/") > 0) {
        return super.appendQueryParams(`${path}/${findArgs}`, "", extension);
      }
      return super.appendQueryParams(path, findArgs, extension);
    }
  }
  _exports.default = CircleAdapter;
});