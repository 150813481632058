define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/multi-select", ["exports", "@ember/helper", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/multi-select", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/base", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _multiSelect, _base, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomFieldMultiSelect extends _base.default {
    get noneLabel() {
      return "discourse_circles.form.select_at_least_one_option";
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldMultiSelect
          ...attributes
          @name={{@field.name}}
          @title={{this.title}}
          @showTitle={{this.shouldDisplayTitle}}
          @description={{this.description}}
          @showDescription={{this.shouldDisplayDescription}}
          @placeholder={{this.placeholder}}
          @showPlaceholder={{this.shouldDisplayPlaceholder}}
          @required={{@field.required}}
          @content={{@field.options}}
          @valueProperty={{null}}
          @nameProperty={{null}}
          @value={{@value}}
          @onChange={{fn (mut @value)}}
          @validation={{@validation}}
          @options={{hash none=this.noneLabel}}
        >
          {{yield}}
        </FormFieldMultiSelect>
      
    */
    {
      "id": "CZ505ejK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@name\",\"@title\",\"@showTitle\",\"@description\",\"@showDescription\",\"@placeholder\",\"@showPlaceholder\",\"@required\",\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\",\"@validation\",\"@options\"],[[30,2,[\"name\"]],[30,0,[\"title\"]],[30,0,[\"shouldDisplayTitle\"]],[30,0,[\"description\"]],[30,0,[\"shouldDisplayDescription\"]],[30,0,[\"placeholder\"]],[30,0,[\"shouldDisplayPlaceholder\"]],[30,2,[\"required\"]],[30,2,[\"options\"]],null,null,[30,3],[28,[32,1],[[28,[31,0],[[30,3]],null]],null],[30,4],[28,[32,2],null,[[\"none\"],[[30,0,[\"noneLabel\"]]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@field\",\"@value\",\"@validation\",\"&default\"],false,[\"mut\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/multi-select.js",
      "scope": () => [_multiSelect.default, _helper.fn, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomFieldMultiSelect;
});