define("discourse/plugins/discourse-circles/discourse/controllers/circle", ["exports", "@ember/controller", "@ember/object", "@ember/service", "I18n"], function (_exports, _controller, _object, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "application", [_controller.inject]))();
    #application = (() => (dt7948.i(this, "application"), void 0))();
    destroying = null;
    showTooltip = false;
    get availableTabs() {
      const userCount = this.model.member_group.user_count;
      const requestCount = this.model.member_group.request_count;
      const staffCount = this.model.staff_group.user_count;
      const defaultTabs = [new Tab({
        name: "about",
        i18nKey: "discourse_circles.circle.about.title"
      })];
      if (this.showActivity) {
        defaultTabs.push(new Tab({
          name: "activity",
          i18nKey: "discourse_circles.circle.activity.title"
        }));
      }
      if (this.showMessages) {
        defaultTabs.push(new Tab({
          name: "messages"
        }));
      }
      if (this.showMembers) {
        defaultTabs.push(new Tab({
          name: "members",
          i18nKey: "discourse_circles.circle.members.title",
          icon: "users",
          options: {
            count: userCount
          }
        }));
      }
      if (this.showRequests) {
        defaultTabs.push(new Tab({
          name: "requests",
          i18nKey: "discourse_circles.circle.requests.title",
          icon: "user-plus",
          options: {
            showNotification: requestCount > 0,
            count: requestCount
          }
        }));
      }
      if (this.showStaff) {
        defaultTabs.push(new Tab({
          name: "staff",
          i18nKey: "discourse_circles.circle.staff.title",
          icon: "users",
          options: {
            count: staffCount
          }
        }));
      }
      if (this.showManage) {
        defaultTabs.push(new Tab({
          name: "manage",
          i18nKey: "discourse_circles.circle.manage.title",
          icon: "wrench"
        }));
      }
      return defaultTabs;
    }
    static #_5 = (() => dt7948.n(this.prototype, "availableTabs", [(0, _object.computed)("showMessages", "showMembers", "showStaff", "showRequests", "showManage", "model.member_group.user_count", "model.member_group.request_count", "model.staff_group.user_count")]))();
    get displayedTabs() {
      return this.availableTabs;
    }
    static #_6 = (() => dt7948.n(this.prototype, "displayedTabs", [(0, _object.computed)("availableTabs")]))();
    get showActivity() {
      return this.model.can_read_topics || this.currentUser || this.siteSettings.discourse_circles_allowed_users_can_see_public_circles_activity;
    }
    get showMembershipButton() {
      return !this.model.is_circle_owner;
    }
    static #_7 = (() => dt7948.n(this.prototype, "showMembershipButton", [(0, _object.computed)("model.is_circle_owner")]))();
    get showMessages() {
      const hasMembersMessages = this.model.member_group.has_messages;
      const isMember = this.model.member_group.is_group_user;
      const hasStaffMessages = this.model.staff_group.has_messages;
      const isStaff = this.model.staff_group.is_group_user;
      if (!this.currentUser?.can_send_private_messages) {
        return false;
      }
      if (!hasMembersMessages && !hasStaffMessages) {
        return false;
      }
      return isMember || isStaff || this.currentUser?.admin;
    }
    static #_8 = (() => dt7948.n(this.prototype, "showMessages", [(0, _object.computed)("model.member_group.has_messages", "model.member_group.is_group_user", "model.staff_group.has_messages", "model.staff_group.is_group_user")]))();
    get showMembers() {
      return this.model.member_group.can_see_members;
    }
    static #_9 = (() => dt7948.n(this.prototype, "showMembers", [(0, _object.computed)("model.member_group.can_see_members")]))();
    get showStaff() {
      return this.currentUser && (this.model.can_manage_staff || this.model.staff_group.is_group_user);
    }
    static #_10 = (() => dt7948.n(this.prototype, "showStaff", [(0, _object.computed)("model.can_manage_staff", "model.staff_group.is_group_user")]))();
    get showRequests() {
      return this.model.can_manage_membership_requests;
    }
    static #_11 = (() => dt7948.n(this.prototype, "showRequests", [(0, _object.computed)("model.can_manage_membership_requests")]))();
    get showManage() {
      return this.model.can_edit;
    }
    static #_12 = (() => dt7948.n(this.prototype, "showManage", [(0, _object.computed)("model.can_edit")]))();
    messageGroup() {
      this.send("createNewMessageViaParams", {
        recipients: this.get("model.name"),
        hasGroups: true
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "messageGroup", [_object.action]))();
    toggleDeleteTooltip() {
      // TODO is this doing anything?
      this.toggleProperty("showTooltip");
    }
    static #_14 = (() => dt7948.n(this.prototype, "toggleDeleteTooltip", [_object.action]))();
  }
  _exports.default = CircleController;
  class Tab {
    #name;
    #route;
    #icon;
    #i18nKey;
    #options;
    constructor(props) {
      const {
        name,
        route,
        icon,
        i18nKey,
        options
      } = props;
      this.#name = name;
      this.#route = route;
      this.#icon = icon;
      this.#i18nKey = i18nKey;
      this.#options = options;
    }
    get name() {
      return this.#name;
    }
    get route() {
      return this.#route || `circle.${this.#name}`;
    }
    get icon() {
      return this.#icon;
    }
    get title() {
      return _I18n.default.t(this.#i18nKey || `discourse_circles.circle.${this.#name}`);
    }
    get options() {
      return this.#options || {};
    }
  }
});