define("discourse/plugins/discourse-circles/discourse/models/circle", ["exports", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/group", "discourse-common/lib/get-owner", "I18n", "discourse/plugins/discourse-circles/discourse/lib/model-attribute", "discourse/plugins/discourse-circles/discourse/models/attributtes"], function (_exports, _tracking, _object, _ajax, _ajaxError, _group, _getOwner, _I18n, _modelAttribute, _attributtes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Circle extends _attributtes.default {
    static PRIVACY_LEVELS = {
      public: {
        value: 0,
        icon: "discourse_circles_public_circle"
      },
      private: {
        value: 1,
        icon: "discourse_circles_private_circle"
      }
    };
    static #attributes;
    static #customFieldAttributes;
    static attributes() {
      const siteSettings = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
      if (!this.#attributes) {
        this.#attributes = Object.freeze([new _modelAttribute.default("name", {
          displayName: "discourse_circles.circle_name",
          validation: {
            async: true,
            presence: true,
            minLength: siteSettings.min_username_length,
            maxLength: siteSettings.discourse_circles_max_circle_name_length,
            custom: async (value, _, options) => {
              const {
                updateStatus,
                model
              } = options;
              let success = null;
              const errors = [];

              // return early if the name hasn't changed
              if (model?.name === value) {
                return {
                  success: true
                };
              }

              // query the server to verify if the name is available
              try {
                updateStatus?.("loading", _I18n.default.t("discourse_circles.form.name.checking"));
                const response = await this.checkName(value);
                if (response.available) {
                  success = _I18n.default.t("discourse_circles.form.name.available");
                } else {
                  if (response.errors) {
                    errors.push(...response.errors);
                  } else {
                    errors.push(_I18n.default.t("discourse_circles.form.name.not_available"));
                  }
                }
              } catch (error) {
                errors.push(_I18n.default.t("discourse_circles.form.validation_error"));
                (0, _ajaxError.popupAjaxError)(error);
              }
              return {
                success,
                errors
              };
            }
          }
        }), new _modelAttribute.default("definition_raw", {
          displayName: "discourse_circles.circle_definition",
          validation: {
            presence: true,
            minLength: siteSettings.min_first_post_length,
            maxLength: siteSettings.discourse_circles_max_circle_definition_length
          }
        }), new _modelAttribute.default("privacy_level", {
          defaultValue: this.PRIVACY_LEVELS.public.value,
          validation: {
            presence: true
          }
        }), new _modelAttribute.default("mentionable_level", {
          defaultValue: siteSettings.discourse_circles_default_mentionable_level
        }), new _modelAttribute.default("uploaded_logo"), new _modelAttribute.default("uploaded_logo_dark"), new _modelAttribute.default("uploaded_background"), new _modelAttribute.default("uploaded_background_dark")]);
      }
      return [...this.#attributes, ...this.customFieldAttributes()];
    }
    static customFieldAttributes() {
      return this.#customFieldAttributes || [];
    }
    static updateCustomFieldsAttributes(customFieldDescriptors) {
      if (!customFieldDescriptors) {
        return;
      }
      this.#customFieldAttributes = customFieldDescriptors.map(field => {
        return new _modelAttribute.default(`field_${field.id}`, {
          isCustomField: true,
          id: field.id,
          displayName: field.name,
          description: field.description,
          fieldType: field.field_type,
          editable: field.editable,
          searchable: field.searchable,
          position: field.position,
          options: field.options,
          // in this context options is the list of items used in select fields
          displayOnAboutPage: field.display_on_about_page,
          displayOnIndexHeader: field.display_on_index_header,
          displayOnCard: field.display_on_card,
          validation: {
            presence: field.required
          }
        });
      });
      return this.#customFieldAttributes;
    }
    static munge(json) {
      const store = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:store");
      if (json?.category) {
        const site = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site");
        const category = store.createRecord("category", json.category);
        if (category.topics) {
          category.topics = category.topics.map(t => store.createRecord("topic", t));
        }
        site.updateCategory(category);
        json.category = category;
      }
      if (json?.staff_group) {
        json.staff_group = _group.default.create(json.staff_group);
      }
      if (json?.member_group) {
        json.member_group = _group.default.create(json.member_group);
      }
      this.customFieldAttributes().forEach(field => {
        const fieldId = field.options.id;
        if (json?.custom_fields?.[fieldId]) {
          json[`field_${fieldId}`] = json.custom_fields[fieldId];
        }
      });
      return json;
    }
    static checkName(name) {
      return (0, _ajax.ajax)("/circles/check-name", {
        data: {
          name
        }
      });
    }
    static async handleMembershipRequest(categoryId, data) {
      return (0, _ajax.ajax)(`/circles/${categoryId}/handle_membership_request.json`, {
        data,
        type: "PUT"
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "category_id", [_tracking.tracked]))();
    #category_id = (() => (dt7948.i(this, "category_id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "slug", [_tracking.tracked]))();
    #slug = (() => (dt7948.i(this, "slug"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "privacy_level", [_tracking.tracked]))();
    #privacy_level = (() => (dt7948.i(this, "privacy_level"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "is_circle_member", [_tracking.tracked]))();
    #is_circle_member = (() => (dt7948.i(this, "is_circle_member"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "can_create_topic", [_tracking.tracked]))();
    #can_create_topic = (() => (dt7948.i(this, "can_create_topic"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "owners", [_tracking.tracked]))();
    #owners = (() => (dt7948.i(this, "owners"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "member_group", [_tracking.tracked]))();
    #member_group = (() => (dt7948.i(this, "member_group"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "staff_group", [_tracking.tracked]))();
    #staff_group = (() => (dt7948.i(this, "staff_group"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "category", [_tracking.tracked]))();
    #category = (() => (dt7948.i(this, "category"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "_uploaded_logo", [_tracking.tracked]))();
    #_uploaded_logo = (() => (dt7948.i(this, "_uploaded_logo"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "_uploaded_logo_dark", [_tracking.tracked]))();
    #_uploaded_logo_dark = (() => (dt7948.i(this, "_uploaded_logo_dark"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "_uploaded_background", [_tracking.tracked]))();
    #_uploaded_background = (() => (dt7948.i(this, "_uploaded_background"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "_uploaded_background_dark", [_tracking.tracked]))();
    #_uploaded_background_dark = (() => (dt7948.i(this, "_uploaded_background_dark"), void 0))();
    get isPublic() {
      return this.privacy_level === Circle.PRIVACY_LEVELS.public.value;
    }
    get isPrivate() {
      return this.privacy_level === Circle.PRIVACY_LEVELS.private.value;
    }
    get hasLogo() {
      return !!this.category?.uploaded_logo?.url;
    }
    get hasBackground() {
      return !!this.category?.uploaded_background?.url;
    }
    get uploaded_logo() {
      return this._uploaded_logo;
    }
    set uploaded_logo(value) {
      const {
        id,
        url,
        width,
        height
      } = value || {};
      const uploadedLogo = new UploadedImage();
      uploadedLogo.id = id;
      uploadedLogo.url = url;
      uploadedLogo.width = width;
      uploadedLogo.height = height;
      this._uploaded_logo = uploadedLogo;
    }
    get uploaded_logo_dark() {
      return this._uploaded_logo_dark;
    }
    set uploaded_logo_dark(value) {
      const {
        id,
        url,
        width,
        height
      } = value || {};
      const uploadedLogoDark = new UploadedImage();
      uploadedLogoDark.id = id;
      uploadedLogoDark.url = url;
      uploadedLogoDark.width = width;
      uploadedLogoDark.height = height;
      this._uploaded_logo_dark = uploadedLogoDark;
    }
    get uploaded_background() {
      return this._uploaded_background;
    }
    set uploaded_background(value) {
      const {
        id,
        url,
        width,
        height
      } = value || {};
      const uploadedBackground = new UploadedImage();
      uploadedBackground.id = id;
      uploadedBackground.url = url;
      uploadedBackground.width = width;
      uploadedBackground.height = height;
      this._uploaded_background = uploadedBackground;
    }
    get uploaded_background_dark() {
      return this._uploaded_background_dark;
    }
    set uploaded_background_dark(value) {
      const {
        id,
        url,
        width,
        height
      } = value || {};
      const uploadedBackgroundDark = new UploadedImage();
      uploadedBackgroundDark.id = id;
      uploadedBackgroundDark.url = url;
      uploadedBackgroundDark.width = width;
      uploadedBackgroundDark.height = height;
      this._uploaded_background_dark = uploadedBackgroundDark;
    }
    get groups() {
      return [this.member_group, this.staff_group];
    }
    get slug_path_with_id() {
      if (!this.slug) {
        return this.category_id;
      }
      return `${this.slug}/${this.category_id}`;
    }
    static #_15 = (() => dt7948.n(this.prototype, "slug_path_with_id", [(0, _object.computed)("category_id", "slug")]))();
    updateFromJson(json) {
      this.setProperties(this.constructor.munge(json));
      return this;
    }
    async reload() {
      if (this.category_id === null) {
        return;
      }
      const json = await (0, _ajax.ajax)(`/circles/${this.slug_path_with_id}.json`, {
        type: "GET"
      });
      this.updateFromJson(json.circle);
    }
    save() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const {
        name,
        definition_raw,
        privacy_level,
        uploaded_logo,
        uploaded_logo_dark,
        uploaded_background,
        uploaded_background_dark,
        ...opts
      } = data;
      const isInserting = this.category_id == null;
      return (0, _ajax.ajax)(isInserting ? "/circles.json" : `${this.#apiPath}.json`, {
        type: isInserting ? "POST" : "PUT",
        data: {
          name,
          definition_raw,
          privacy_level,
          uploaded_logo_id: uploaded_logo?.id,
          uploaded_logo_dark_id: uploaded_logo_dark?.id,
          uploaded_background_id: uploaded_background?.id,
          uploaded_background_dark_id: uploaded_background_dark?.id,
          ...opts
        }
      }).then(response => {
        this.updateFromJson(response.circle);
        return this;
      });
    }
    destroy() {
      if (!this.category_id) {
        return;
      }
      return (0, _ajax.ajax)(`${this.#apiPath}.json`, {
        type: "DELETE"
      });
    }
    async join() {
      await (0, _ajax.ajax)(`/groups/${this.member_group.id}/join.json`, {
        type: "PUT"
      });
      await this.member_group.reloadMembers({}, true);
    }
    async addMembers(usernames) {
      let staff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let notifyUsers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      return await (0, _ajax.ajax)(`${this.#apiPath}/members.json`, {
        type: "POST",
        data: {
          usernames,
          notifyUsers,
          staff
        }
      });
    }
    async changeOwnership(mode, params) {
      let requestType;
      switch (mode) {
        case "add":
          requestType = "POST";
          break;
        case "remove":
          requestType = "DELETE";
          break;
        case "transfer":
          requestType = "PUT";
          break;
        default:
          throw new Error(`circle#changeOwnership mode ${mode} is unknown`);
      }
      const response = await (0, _ajax.ajax)(`${this.#apiPath}/ownership`, {
        type: requestType,
        data: params
      });
      const {
        circle: updatedCircle
      } = response;
      this.updateFromJson(updatedCircle);
    }
    async requestMembership(reason) {
      return (0, _ajax.ajax)(`${this.#apiPath}/request_membership.json`, {
        type: "POST",
        data: {
          reason
        }
      });
    }
    async leave() {
      if (!this.category_id) {
        return;
      }
      await (0, _ajax.ajax)(`${this.#apiPath}/leave.json`, {
        type: "DELETE"
      });
      await this.reload();
    }
    async cancelMembershipRequest() {
      if (!this.category_id) {
        return;
      }
      await (0, _ajax.ajax)(`${this.#apiPath}/cancel_membership_request.json`, {
        type: "DELETE"
      });
      await this.reload();
    }
    async reloadCustomFields() {
      await this.constructor.reloadCustomFields();
    }
    get #apiPath() {
      return `/circles/${this.category_id}`;
    }
  }
  _exports.default = Circle;
  class UploadedImage {
    static #_ = (() => dt7948.g(this.prototype, "id", [_tracking.tracked]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "url", [_tracking.tracked]))();
    #url = (() => (dt7948.i(this, "url"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "width", [_tracking.tracked]))();
    #width = (() => (dt7948.i(this, "width"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "height", [_tracking.tracked]))();
    #height = (() => (dt7948.i(this, "height"), void 0))();
    get aspectRatio() {
      return this.width / this.height;
    }
  }
});