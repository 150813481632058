define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/checkbox", ["exports", "@ember/component", "discourse-common/helpers/i18n", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", "@ember/template-factory"], function (_exports, _component, _i18n, _base, _wireFrame, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormFieldCheckbox extends _base.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FormFieldWireFrame
          @fieldName={{this.fieldName}}
          @title={{this.title}}
          @hideDescription={{true}}
          @required={{@required}}
          @validation={{@validation}}
        >
          <label class="control-label checkbox-label">
            <Input
              class="circles-form-{{@name}}"
              ...attributes
              name={{this.fieldName}}
              @checked={{@value}}
              @type="checkbox"
            />
            <span>
              <span>
                {{this.description}}
              </span>
              {{#unless @title}}
                {{#if @required}}
                  <span class="required_flag">
                    {{i18n "discourse_circles.form.required_field_indicator"}}
                  </span>
                {{/if}}
              {{/unless}}
            </span>
          </label>
          {{yield}}
        </FormFieldWireFrame>
      
    */
    {
      "id": "VWNR8Uyi",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@fieldName\",\"@title\",\"@hideDescription\",\"@required\",\"@validation\"],[[30,0,[\"fieldName\"]],[30,0,[\"title\"]],true,[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label checkbox-label\"],[12],[1,\"\\n        \"],[8,[32,1],[[16,0,[29,[\"circles-form-\",[30,3]]]],[17,4],[16,3,[30,0,[\"fieldName\"]]]],[[\"@checked\",\"@type\"],[[30,5],\"checkbox\"]],null],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[30,0,[\"description\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[41,[51,[30,6]],[[[41,[30,1],[[[1,\"              \"],[10,1],[14,0,\"required_flag\"],[12],[1,\"\\n                \"],[1,[28,[32,2],[\"discourse_circles.form.required_field_indicator\"],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@required\",\"@validation\",\"@name\",\"&attrs\",\"@value\",\"@title\",\"&default\"],false,[\"unless\",\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/checkbox.js",
      "scope": () => [_wireFrame.default, _component.Input, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldCheckbox;
});