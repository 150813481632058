define("discourse/plugins/discourse-circles/discourse/lib/html-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringToClassname = stringToClassname;
  function stringToClassname(value) {
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().replace(/[^a-z0-9]/g, "-").replace(/-+/g, "-");
  }
});