define("discourse/plugins/discourse-circles/discourse/models/attributtes", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AttributesModel extends _rest.default {
    static attributes() {
      throw new Error("attributes() must be implemented.");
    }
    static attributeNames() {
      return this.attributes().map(a => a.name);
    }
    static attributesDefaults() {
      return this.attributes().reduce((obj, v) => {
        obj[v.name] = v.defaultValue;
        return obj;
      }, {});
    }
    static attributeByName(name) {
      return this.attributes().find(a => a.name === name);
    }
  }
  _exports.default = AttributesModel;
});