define("discourse/plugins/discourse-circles/discourse/templates/circle/activity/topics", ["exports", "@glimmer/component", "@ember/helper", "ember-route-template", "discourse/components/basic-topic-list", "discourse/components/conditional-loading-spinner", "discourse/components/load-more", "discourse/components/plugin-outlet", "discourse-common/helpers/i18n", "truth-helpers/helpers/or", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _emberRouteTemplate, _basicTopicList, _conditionalLoadingSpinner, _loadMore, _pluginOutlet, _i18n, _or, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CircleAbout extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <PluginOutlet
            @name="circles-activity-topics"
            @outletArgs={{hash circleModel=@controller.circleModel model=@model}}
          >
            {{#if @controller.circleModel.can_read_topics}}
              <div class="circles-activity-topics-wrapper">
                <PluginOutlet
                  @name="circles-activity-topics-paginated-list"
                  @outletArgs={{hash
                    circleModel=@controller.circleModel
                    model=@model
                  }}
                >
                  <LoadMore
                    @action={{@controller.loadMore}}
                    @selector=".paginated-topics-list .topic-list tr"
                    class="paginated-topics-list"
                  >
                    {{#if (or @model.topics @controller.loadingMore)}}
                      <BasicTopicList @topicList={{@model}} @showPosters={{true}} />
                      <ConditionalLoadingSpinner
                        @condition={{@model.loadingMore}}
                      />
                    {{else}}
                      <div class="alert alert-info alert-no-topics">
                        {{i18n "discourse_circles.circle.no_topics"}}
                      </div>
                    {{/if}}
                  </LoadMore>
                </PluginOutlet>
              </div>
            {{else if @controller.circleModel.isPublic}}
              {{i18n "discourse_circles.circle.activity.cant_read_public"}}
            {{else if @controller.circleModel.isPrivate}}
              {{i18n "discourse_circles.circle.activity.cant_read_private"}}
            {{else}}
              {{i18n "discourse_circles.circle.activity.cant_read_other"}}
            {{/if}}
          </PluginOutlet>
        
    */
    {
      "id": "LRuLnXqO",
      "block": "[[[1,\"\\n      \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-activity-topics\",[28,[32,1],null,[[\"circleModel\",\"model\"],[[30,1,[\"circleModel\"]],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"circleModel\",\"can_read_topics\"]],[[[1,\"          \"],[10,0],[14,0,\"circles-activity-topics-wrapper\"],[12],[1,\"\\n            \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-activity-topics-paginated-list\",[28,[32,1],null,[[\"circleModel\",\"model\"],[[30,1,[\"circleModel\"]],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[32,2],[[24,0,\"paginated-topics-list\"]],[[\"@action\",\"@selector\"],[[30,1,[\"loadMore\"]],\".paginated-topics-list .topic-list tr\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[32,3],[[30,2,[\"topics\"]],[30,1,[\"loadingMore\"]]],null],[[[1,\"                  \"],[8,[32,4],null,[[\"@topicList\",\"@showPosters\"],[[30,2],true]],null],[1,\"\\n                  \"],[8,[32,5],null,[[\"@condition\"],[[30,2,[\"loadingMore\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                  \"],[10,0],[14,0,\"alert alert-info alert-no-topics\"],[12],[1,\"\\n                    \"],[1,[28,[32,6],[\"discourse_circles.circle.no_topics\"],null]],[1,\"\\n                  \"],[13],[1,\"\\n\"]],[]]],[1,\"              \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"circleModel\",\"isPublic\"]],[[[1,\"          \"],[1,[28,[32,6],[\"discourse_circles.circle.activity.cant_read_public\"],null]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"circleModel\",\"isPrivate\"]],[[[1,\"          \"],[1,[28,[32,6],[\"discourse_circles.circle.activity.cant_read_private\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,6],[\"discourse_circles.circle.activity.cant_read_other\"],null]],[1,\"\\n        \"]],[]]]],[]]]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[\"@controller\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circle/activity/topics.js",
      "scope": () => [_pluginOutlet.default, _helper.hash, _loadMore.default, _or.default, _basicTopicList.default, _conditionalLoadingSpinner.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  });
});