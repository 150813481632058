define("discourse/plugins/discourse-circles/discourse/routes/admin-plugins-circles-custom-fields", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-circles/discourse/models/circle-custom-field"], function (_exports, _discourse, _circleCustomField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsCirclesCustomFieldsRoute extends _discourse.default {
    model() {
      return this.store.findAll("circle-custom-field");
    }
    setupController(controller, model) {
      controller.setProperties({
        model,
        fieldTypes: _circleCustomField.default.fieldTypes()
      });
    }
  }
  _exports.default = AdminPluginsCirclesCustomFieldsRoute;
});