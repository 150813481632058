define("discourse/plugins/discourse-circles/discourse/templates/circle/manage", ["exports", "@glimmer/component", "@ember/routing", "ember-route-template", "discourse/components/mobile-nav", "discourse/components/plugin-outlet", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _emberRouteTemplate, _mobileNav, _pluginOutlet, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CircleManage extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <section class="circles-secondary-navigation circles-manage-navigation">
            <MobileNav
              @desktopClass="action-list activity-list nav-stacked"
              class="activity-nav"
            >
              <PluginOutlet @name="circles-manage-navigation-items">
                {{#if this.model.member_group.can_see_members}}
                  <li>
                    <LinkTo @route="circle.manage.profile">
                      {{i18n "discourse_circles.circle.manage.profile.title"}}
                    </LinkTo>
                  </li>
                {{/if}}
    
                <li>
                  <LinkTo @route="circle.manage.advanced">
                    {{i18n "discourse_circles.circle.manage.advanced.title"}}
                  </LinkTo>
                </li>
              </PluginOutlet>
            </MobileNav>
          </section>
    
          <section class="circles-tab-content circles-manage-content">
            {{outlet}}
          </section>
        
    */
    {
      "id": "1XesmpnO",
      "block": "[[[1,\"\\n      \"],[10,\"section\"],[14,0,\"circles-secondary-navigation circles-manage-navigation\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"activity-nav\"]],[[\"@desktopClass\"],[\"action-list activity-list nav-stacked\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,1],null,[[\"@name\"],[\"circles-manage-navigation-items\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"member_group\",\"can_see_members\"]],[[[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,2],null,[[\"@route\"],[\"circle.manage.profile\"]],[[\"default\"],[[[[1,\"\\n                  \"],[1,[28,[32,3],[\"discourse_circles.circle.manage.profile.title\"],null]],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[32,2],null,[[\"@route\"],[\"circle.manage.advanced\"]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[32,3],[\"discourse_circles.circle.manage.advanced.title\"],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,\"section\"],[14,0,\"circles-tab-content circles-manage-content\"],[12],[1,\"\\n        \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[],false,[\"if\",\"component\",\"-outlet\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circle/manage.js",
      "scope": () => [_mobileNav.default, _pluginOutlet.default, _routing.LinkTo, _i18n.default],
      "isStrictMode": true
    }), this))();
  });
});