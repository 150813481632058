define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/custom", ["exports", "@glimmer/component", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/combobox", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/confirm", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/multi-select", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _combobox, _confirm, _multiSelect, _text, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPONENTS = {
    confirm: _confirm.default,
    dropdown: _combobox.default,
    multiselect: _multiSelect.default,
    text: _text.default
  };
  class FormFieldCustom extends _component.default {
    get component() {
      return COMPONENTS[this.args.filter.type] || COMPONENTS.text;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <this.component
          class="circles-search-filter__custom"
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @placeholder={{@placeholder}}
          @value={{@value}}
        />
      
    */
    {
      "id": "otcicuIZ",
      "block": "[[[1,\"\\n    \"],[8,[30,0,[\"component\"]],[[24,0,\"circles-search-filter__custom\"],[17,1]],[[\"@controller\",\"@filter\",\"@placeholder\",\"@value\"],[[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@placeholder\",\"@value\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/custom.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FormFieldCustom;
});